<template>
<div>
  <div>
    <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
        <div class="tw-shadow tw-overflow-hidden sm:tw-rounded-md">
            <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                <FormulateForm v-model="prePopulate" name="fluConsnetText" :schema="fluConsentTextSchema" :keep-model-data="true" />
            </div>
        </div>
    </div>
    <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
        <div class="tw-shadow tw-overflow-hidden sm:tw-rounded-md">
            <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6">
                <FormulateForm v-model="prePopulate" name="fluConsentRadioSchema" :schema="fluConsentRadioSchema" :keep-model-data="true" />
            </div>
        </div>
    </div>
    </div>
    <v-card v-show="signatureStatus">
        <VueSignaturePad :key="refreshSignatureStatus" ref="signaturePad" width="500px" height="500px" :options="{ onBegin, onEnd }" />
        <div>
            <v-btn color="primary" @click="save">
                Save
            </v-btn>
            <v-btn color="primary" @click="undo">
                Undo
            </v-btn>
            <v-btn color="primary" @click="hideSignaturePanel">
                Close Signature
            </v-btn>
        </div>
    </v-card>
    <v-row class="row justify-center align-center">
        <v-card-actions>
            <v-col>
                <div class="demo-space-x">

                    <v-btn color="success" dark form-type="fluNew" base-filename="fluNew" :patient-data="prePopulate" @click="sendtoZenyata">
                        Submit Report
                    </v-btn>

                </div>
            </v-col>
        </v-card-actions>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline text-xs-center">
                Submitting...
            </v-card-title>
            <v-card-text class="text-xs-center">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import { WebRTC } from 'vue-webrtc'
import * as path from 'path'

// const socket = io('https://pharmaguide.ngrok.io')

Vue.component(WebRTC.name, WebRTC)

const socket = io('https://pharmaguide.ngrok.io')
// const socket = io('http://localhost:3001')

Vue.use(VueSocketIOExt, socket)

// https://github.com/probil/vue-socket.io-extended
export default {
  name: 'Portal',
  data() {
    return {
      dialog: false,
      pdfRef: '',
      date: '',
      newMessage: null,
      refreshSignatureStatus: 0,
      messages: [],
      typing: false,
      username: null,
      ready: false,
      info: [],
      connections: 0,
      img: null,
      roomId: '',
      signatureStatus: false,
      prePopulate: {},
      fluConsentRadioSchema: [
        {
          component: 'div',
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          children: [],
        },
      ],
      fluConsentTextSchema: [
        {
          component: 'h3',
          children: 'Patient Information',
          class: 'tw-text-2xl tw-mb-4',
        },
        {
          component: 'div',
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          children: [],
        },
      ],
    }
  },
  sockets: {
    beforeMount() {
      this.roomId = this.$route.params.roomId
    },
    connect() {
      console.log('socket connected')
    },
    customEmit(val) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    },
    message(data) {
      this.messages.push(data)
      this.fillfrom()
    },
  },
  mounted() {
    // subscribe
    this.currentDate()
    this.$socket.client.on('eventName', this.onEventName) // <-- this.onEventName here
  },
  beforeDestroy() {
    // unsubscribe
    this.$socket.client.off('eventName', this.onEventName) // <-- this.onEventName here
  },
  methods: {
    currentDate() {
      // const current = new Date()
      // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      const date = new Date().toISOString().substr(0, 10)
      return date
    },
    sendtoZenyata() {
      this.dialog = true
      var axios = require('axios')
      var data = JSON.stringify({
        ...this.prePopulate,
        FORM_CLASS: 'fluNew',
        filename: 'fluNewblah',
      })

      var config = {
        method: 'post',
        url: 'https://pdfdev.pharmaguide.ca/process.php',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          const pdfRef = path.basename(response.data.pdfUrl).split('.').slice(0, -1).join('.')
          return pdfRef
        })
        .then(async pdfRef => {
          var axios = require('axios')
          var accessToken = await this.$auth.getTokenSilently()
          await axios
            .get(`https://apidev.pharmaguide.ca/pdf/${pdfRef}`, {
              responseType: 'arraybuffer',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/pdf',
              },
            })
            .then(resp => resp.data)

            .then(blobData => {
              fileDownload(blobData, `fluNew`.toLowerCase(), 'application/octet-stream')
              this.dialog = false
            })
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getPdf(pdfRef) {
      const accessToken = await this.$auth.getTokenSilently()
      var axios = require('axios')
      var config = {
        method: 'get',
        url: `https://apidev.pharmaguide.ca/pdf/${pdfRef}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      axios(config).then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      // .then(blobData => {
      //   fileDownload(blobData, `cardioReport-${moment().format('YYYY-MM-DD')}`.toLowerCase())
      // })
    },

    onBegin() {
      console.log('=== Begin ===')
    },
    onEnd() {
      //   if ((this.refreshSignatureStatus = 1)) {
      // this.refreshSignatureStatus += 1
      //   }

      console.log('=== End ===')
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      console.log(isEmpty)
      console.log(data)
    },
    hideSignaturePanel() {
      this.signatureStatus = false
    },
    fillfrom() {
      this.prePopulate = this.messages[0]
      this.fluConsentRadioSchema[0].children = this.messages[0].fluConsentRadioSchema
      this.fluConsentTextSchema[1].children = this.messages[0].fluConsentTextSchema
      // this.signatureStatus = true
      this.signatureStatus = !this.signatureStatus
      this.refreshSignatureStatus += 1
    },
    clickButton(val) {
      // this.$socket.client is `socket.io-client` instance
      this.$socket.client.emit('emit_method', val)
    },
    onEventName(params) {
      console.log('`eventName` has fired with:', params)
    },
    onCapture() {
      this.img = this.$refs.webrtc.capture()
    },
    onJoin() {
      this.$refs.webrtc.join()
    },
    onLeave() {
      this.$refs.webrtc.leave()
    },
    onShareScreen() {
      this.img = this.$refs.webrtc.shareScreen()
    },
    onError(error, stream) {
      console.log('On Error Event', error, stream)
    },
    logEvent(event) {
      console.log('Event : ', event)
    },
  },
}

function fileDownload(data, filename, mime) {
  var blob = new Blob([data], {
    type: mime || 'application/octet-stream',
  })
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, `${filename}.pdf`)
  } else {
    var blobURL = window.URL.createObjectURL(blob)
    var tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `${filename}.pdf`)

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }

    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}
</script>
